import { Injectable } from '@angular/core';
import { init, Data } from 'emoji-mart';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class EmojisService {
  public customEmojis: any[];

  isLoaded = false;

  constructor(private authService: AuthService) {}

  async loadEmojis() {
    if (this.isLoaded) {
      return;
    }

    const customEmojis: Record<string, string> = this.authService.tenant.customEmojis ?? {};
    this.customEmojis = Object.entries(customEmojis)
      .filter(([key, value]) => value.startsWith('https://'))
      .map(([key, value]) => ({
        id: key,
        name: key,
        keywords: ['custom'],
        skins: [
          {
            src: value,
          },
        ],
      }));

    await init({
      data: async () => {
        const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data');

        return response.json();
      },
      custom: [
        {
          id: 'custom',
          name: 'Custom',
          emojis: this.customEmojis,
        },
      ],
    }),
      (this.isLoaded = true);
  }

  async getEmojiList() {
    await this.loadEmojis();
    return Data?.emojis;
  }
}
